import React, { useEffect, useState } from 'react';

import { Flex, Button, Alert, AlertTitle, AlertIcon } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../contexts/userAuth';
import { validatePublic } from '../../libs/show-api';
import { ChevronRightIcon } from '@chakra-ui/icons';

export const AccessPublic = (props: any) => {
    const { showId } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { setAuthenticated } = React.useContext(AuthContext);

    const { handleSubmit } = useForm({
        mode: 'onChange',
    });

    const onSubmit = (data: any) => {
        setIsLoading(true);
        setError(null);
        validatePublic(showId)
            .then((data: any) => {
                setIsLoading(false);
                setAuthenticated(true);
            })
            .catch((err: any) => {
                setError(err.msg);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 10000);
        }
    }, [error]);

    return (
        <Flex direction="column">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Button
                    size="lg"
                    width={{ base: '100%', sm: 'auto' }}
                    bgColor="white"
                    color="black"
                    _hover={{
                        background: 'gray.300',
                    }}
                    mt={2}
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                    id="validatePublicBtn"
                    rightIcon={<ChevronRightIcon />}
                >
                    Ver el Evento
                </Button>
            </form>
            {error && (
                <Alert status="error" mt={2} borderRadius="6px">
                    <AlertIcon />
                    <AlertTitle mr={2}>{error}</AlertTitle>
                </Alert>
            )}
        </Flex>
    );
};
