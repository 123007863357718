import React from 'react';

import { Alert, AlertTitle, Heading } from '@chakra-ui/react';

export const AccessError = () => {
    return (
        <Alert status="error" mt={2} borderRadius="6px" p={8}>
            <AlertTitle mr={4}>
                <Heading size="xl">
                    No se ha encontrado el evento o bien el mismo ha finalizado.
                </Heading>
            </AlertTitle>
        </Alert>
    );
};
