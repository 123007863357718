import axios, { AxiosInstance, AxiosResponse } from "axios";

const FormData = require('form-data');

const axiosInstance: AxiosInstance = axios.create({
    headers: {
        'Content-type': 'application/json'
    }
});

// Add a request interceptor
axiosInstance.interceptors.request.use(async function (config: any) {

    return config;
});


function objectToQueryString(obj: any) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}


const get = async (url: string, queryParams: any = null): Promise<any> => {

    try {
        if (queryParams) {
            const queryString = objectToQueryString(queryParams);
            url += (url.includes('?'))
                ? '&' + queryString
                : '?' + queryString;
        }

        const res = await axiosInstance.get(url);
        return processResponse(res);

    }
    catch (err) {
        return new Promise((resolve, reject) => {
            reject(err);
        });
    }
}

const post = async (url: string, data: any) => {
    const res = await axiosInstance.post(url, data);
    return processResponse(res);
}

const postFiles = async (url: string, data: any, files: File[]) => {
    const _data = new FormData();
    if (files && files.length > 0) {
        files.forEach((file) => {
            _data.append('file', file);
        })
    }
    if (data)
        _data.append('listing', JSON.stringify(data));
    const res = await axiosInstance.post(url, _data);
    return processResponse(res);
}

const put = async (url: string, data: any) => {
    const res = await axiosInstance.put(url, data);
    return processResponse(res);
}

const patch = async (url: string, data: any) => {
    const res = await axiosInstance.patch(url, data);
    return processResponse(res);
}

const del = async (url: string) => {
    const res = await axiosInstance.delete(url);
    return processResponse(res);
}

const processResponse = (res: AxiosResponse): Promise<any> => {

    if (res.status >= 200 && res.status < 300) {
        return new Promise((resolve, reject) => {
            resolve(res.data);
        });

        /*
        if (res.data && res.data.meta && (res.data.meta.code === 200 && res.data.meta.code < 300)) {
            return new Promise((resolve, reject) => {
                resolve(res.data.response)
            });
        }
        */
    }

    return new Promise((resolve, reject) => {
        reject(res.data)
    });

}



async function fetchGetJSON(url: string) {
    try {
        const data = await fetch(url).then((res) => res.json())
        return data
    } catch (err: any) {
        throw new Error(err.message)
    }
}


async function fetchPostJSON(url: string, data?: {}) {
    try {

        // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
        })
        return await response.json() // parses JSON response into native JavaScript objects
    } catch (err: any) {
        throw new Error(err.message)
    }
}


export const httpClient = {
    get,
    post,
    postFiles,
    delete: del,
    put,
    patch,
    fetchGetJSON,
    fetchPostJSON
}
