import React from 'react';

import { Flex, Text, Box, Image, Link } from '@chakra-ui/react';

export const SponsorBox = (props: any) => {
    const { sponsor } = props;

    if (!sponsor) return <></>;

    return (
        <Flex direction="column" spacing={4}>
            <Text display={{ base: 'block', sm: 'none' }} mb={2}>
                Contenido patrocinado por
            </Text>

            <Flex
                direction={{ base: 'column', sm: 'row' }}
                bgColor="gray.900"
                align={{ base: 'center', sm: 'left' }}
                px={4}
                py={{ base: 5, sm: 5 }}
                borderRadius="5px"
            >
                <Flex
                    height={{ base: '70px', sm: '80px' }}
                    pt="0"
                    width={{ base: '100%', sm: '20%' }}
                >
                    {sponsor.website && sponsor.website.url ? (
                        <Flex align="center">
                            <Link
                                maxW={'261.11px'}
                                href={sponsor.website.url}
                                isExternal
                            >
                                {sponsor.image && sponsor.image.logo ? (
                                    <Image
                                        width="100%"
                                        fit={'cover'}
                                        src={sponsor.image.logo}
                                        alt={sponsor.name}
                                        _hover={{
                                            transform: 'scale(1.01)',
                                        }}
                                    />
                                ) : (
                                    <Text>{sponsor.name}</Text>
                                )}
                            </Link>
                        </Flex>
                    ) : (
                        <Image
                            width="100%"
                            fit={'cover'}
                            src={sponsor.image.logo}
                            _hover={{
                                transform: 'scale(1.01)',
                            }}
                        />
                    )}
                </Flex>
                <Flex
                    direction="column"
                    justify="center"
                    ml={{ base: 0, sm: 9 }}
                    display={{ base: 'none', sm: 'block' }}
                    flex={1}
                >
                    <Box
                        fontSize={{ base: 'sm', sm: 'md', md: 'xl' }}
                        mb={4}
                        textAlign={{ base: 'center', sm: 'left' }}
                        pt={{ base: 2, sm: 0 }}
                    >
                        {sponsor.description ||
                            `Este contenido es auspiciado por ${sponsor.name}`}
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
