import { httpClient } from "../utils/http-client";
import { API, ENV, STORAGE_URL } from '../utils/constants';
import { getDevice } from '../utils/fingerprint';
import { saveSession, clearSession } from '../libs/session';

/**
 * Validate if access code exists in a show
 * @param showSlug
 * @param accessCode 
 * @returns 
 */
export const validateAccessCode = async (showSlug: string, accessCode: string) => {
    try {

        const body: any = {
            show: showSlug,
            code: accessCode ? accessCode.toUpperCase() : accessCode,
            env: ENV,
            device: getDevice()
        };

        const res: any = await httpClient.post('' + API.VALIDATE, body);
        if (res.success) {

            // Save session in browser
            saveSession(res.payload.authToken, res.payload.show, res.payload.ticket, res.payload.player);

            return Promise.resolve({
                ticket: res.payload.ticket,
                player: res.payload.player,
                authToken: res.payload.authToken
            });
        } else {
            return Promise.reject({ msg: res.msg || 'Unexpected problem', code: res.code || '' });
        }
    } catch (err: any) {
        return Promise.reject(err);
    }
}


/**
 * Validate public show
 * @param showSlug 
 * @returns 
 */
export const validatePublic = async (showSlug: string) => {
    try {

        const body: any = {
            show: showSlug,
            env: ENV
        };

        const res: any = await httpClient.post('' + API.VALIDATE_PUBLIC, body);
        if (res.success) {

            // Save session in browser
            saveSession(res.payload.authToken, res.payload.show, res.payload.ticket, res.payload.player);

            return Promise.resolve({
                ticket: res.payload.ticket,
                player: res.payload.player,
                authToken: res.payload.authToken
            });
        } else {
            return Promise.reject({ msg: res.msg || 'Unexpected problem' });
        }
    } catch (err: any) {
        return Promise.reject(err);
    }
}


/**
 * Log out from ticket
 * @param showSlug 
 * @param accessCode 
 * @returns 
 */
export const logout = async (showSlug: string, accessCode: string) => {
    try {

        // Clear session in browser
        clearSession();

        // If accessCode sent, then call to API
        if (accessCode) {
            const body: any = {
                show: showSlug,
                code: accessCode,
                env: ENV,
                device: getDevice()
            };

            const res: any = await httpClient.post('' + API.LOGOUT, body);
            if (res.success) {
                return Promise.resolve(true);
            } else {
                return Promise.reject({ msg: res.msg || 'Unexpected problem' });
            }
        }

        // Otherwise, just return true
        else {
            return Promise.resolve(true);
        }

    } catch (err: any) {
        return Promise.reject(err);
    }
}


/**
 * Return show by id
 * @param showId 
 * @returns 
 */
export const findShowById = async (showId: string) => {
    try {
        const baseUrl = `${STORAGE_URL}/${ENV}-shows%2F${showId}%2Fshow.json?alt=media`;

        const res: any = await httpClient.get(baseUrl);
        if (res) {
            return Promise.resolve(res);
        } else {
            // If not found, then look in database
            // return findShowByIdFromDatabase(showId);
            return Promise.resolve(false);
        }
    }
    catch (err) {
        // If error, then look in database
        // return findShowByIdFromDatabase(showId);
        return Promise.resolve(false);
    }
}


/**
 * Return show by id looking in database
 * @param showId 
 * @returns 
 */
export const findShowByIdFromDatabase = async (showId: string) => {
    try {
        const baseUrl = `${API.SHOW}?show=${showId}&env=${ENV}`;

        const res: any = await httpClient.get(baseUrl);
        if (res.success) {
            return Promise.resolve(res.payload);
        } else {
            return Promise.resolve(null);
        }
    }
    catch (err) {
        return Promise.reject(err);
    }
}


/**
 * Find featured shows to display in home page
 * @returns 
 */
export const findFeaturedShows = async () => {
    try {
        const baseUrl = `${STORAGE_URL}/${ENV}-shows%2Fshows.json?alt=media`;

        const res: any = await httpClient.get(baseUrl);
        if (res) {
            return Promise.resolve(res);
        } else {
            return Promise.resolve([])
        }
    }
    catch (err) {
        // If error, then look in database
        return Promise.resolve([])
    }
}
