import React from 'react';

import { AspectRatio } from '@chakra-ui/react';

export const EmbedPlayer = (props: any) => {
    const url = props && props.url;
    if (!url) return null;

    return (
        <AspectRatio maxW="100%" ratio={16 / 9}>
            <iframe title="Watch" src={url} allowFullScreen />
        </AspectRatio>
    );
};
