import { Flex, Box, Badge, Image, Text, Heading, Link } from '@chakra-ui/react';
import { Link as LinkRouter } from 'react-router-dom';
import { formatDate } from '../../utils/formatter';

export const ShowCard = (props: any) => {
    const { show } = props;

    return (
        <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
            {show?.event?.image.cover && (
                <LinkRouter to={`/${show.slug}`}>
                    <Image
                        height="200px"
                        width="100%"
                        objectFit="cover"
                        src={show?.event?.image.cover}
                        _hover={{
                            transform: 'scale(1.01)',
                        }}
                    />
                </LinkRouter>
            )}

            <Box px={4} py={2}>
                {show.event && (
                    <Flex
                        mb={{ base: 1, sm: 4 }}
                        pt={2}
                        direction="row"
                        align="baseline"
                    >
                        <Box>
                            <Badge
                                colorScheme="red"
                                bgColor="red.600"
                                color="white"
                                fontSize="md"
                                px={2}
                            >
                                LIVE
                            </Badge>
                        </Box>
                        <Text ml="3" fontSize={{ base: 'sm', sm: 'sm' }}>
                            {show.event.date !== '' &&
                                formatDate(
                                    show.event.date,
                                    'dddd, d mmm yyyy, h:MM TT'
                                )}
                            {show.event.date === '' && 'Fecha a definir'}
                        </Text>
                    </Flex>
                )}

                {show.event.name && (
                    <LinkRouter to={`/${show.slug}`}>
                        <Heading
                            fontSize="xl"
                            mt={{ base: 2, sm: 0 }}
                            _hover={{ color: 'gray.400' }}
                        >
                            {show.event.name}
                        </Heading>
                    </LinkRouter>
                )}

                {show.sponsor && (
                    <Flex direction="column" mt={2} pb={3}>
                        <Text fontSize="md" mb={2}>
                            Contenido auspiciado por
                        </Text>
                        {show.sponsor.website && show.sponsor.website.url ? (
                            <Link
                                maxW={'150.55px'}
                                href={show.sponsor.website.url}
                                isExternal
                            >
                                <Flex height={{ base: '40px', sm: '40px' }}>
                                    <Image
                                        fit={'cover'}
                                        src={show.sponsor.image?.logo}
                                        alt={show.sponsor.name}
                                        _hover={{
                                            transform: 'scale(1.01)',
                                        }}
                                    />
                                </Flex>
                            </Link>
                        ) : (
                            <Image
                                fit={'cover'}
                                src={show.sponsor.image?.logo}
                                alt={show.sponsor.name}
                                _hover={{
                                    transform: 'scale(1.01)',
                                }}
                            />
                        )}
                    </Flex>
                )}
            </Box>
        </Box>
    );
};
