export const CURRENT_VERSION = require("../../package.json").version;
export const TIMESTAMP_VERSION = process.env.REACT_APP_TIMESTAMP_VERSION || '-';
export const ENV = process.env.REACT_APP_ENV;
export const CONTROL_PANEL = process.env.REACT_APP_CONTROL_PANEL || 'default';

export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

export const APP_NAME = process.env.REACT_APP_NAME;

export const API = {
    SHOW: process.env.REACT_APP_API_SHOW,
    VALIDATE: process.env.REACT_APP_API_VALIDATE,
    VALIDATE_PUBLIC: process.env.REACT_APP_API_VALIDATE_PUBLIC,
    LOGOUT: process.env.REACT_APP_API_LOGOUT
}

export const SHOW = {
    MODE: {
        CLOSED: 'closed',
        OPEN: 'open',
        FINALIZED: 'finalized'
    },
    ACCESS_MODE: {
        VALIDATE: 'validate',
        PUBLIC: 'public'
    }
}
