import React from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/userAuth';

export function PublicRoute({ children, embed, ...rest }: any) {
    const { authenticated, showId } = React.useContext(AuthContext);

    return authenticated === false ? (
        children
    ) : showId ? (
        embed ? (
            <Navigate to={`/embed/${showId}/watch`} />
        ) : (
            <Navigate to={`/${showId}/watch`} />
        )
    ) : (
        <></>
    );
}
