import React from 'react';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const HLSPlayer = (props: any) => {
    const { hls } = props;
    const videoRef = React.useRef(null);

    // This seperate functional component fixes the removal of the videoelement
    // from the DOM when calling the dispose() method on a player
    const VideoHtml = (props: any) => (
        <div data-vjs-player>
            <video ref={videoRef} className="video-js vjs-big-play-centered" />
        </div>
    );

    React.useEffect(() => {
        const options: any = {
            // lookup the options in the docs for more options
            autoplay: true,
            controls: true,
            responsive: true,
            fluid: true,
            sources: [
                {
                    src: hls,
                    type: 'application/x-mpegURL',
                },
            ],
        };

        const videoElement = videoRef.current;
        let player: any;
        if (videoElement) {
            player = videojs(videoElement, options, () => {
                console.log('player is ready');
            });
        }
        return () => {
            if (player) {
                player.dispose();
            }
        };
    }, [hls]);

    return <VideoHtml />;
};
