import React from 'react';

import {
    Flex,
    Text,
    Divider,
    SimpleGrid,
    Box,
    Heading,
    Skeleton,
} from '@chakra-ui/react';
import { ShowCard } from '../show';
import { useQuery } from 'react-query';
import { findFeaturedShows } from '../../libs/show-api';
import { Logo } from '../common';

export const HomeScreen = () => {
    const { data: shows, isLoading } = useQuery(['shows'], () =>
        findFeaturedShows()
    );

    return (
        <Flex direction="column" pt={8} justify="center">
            <Flex direction="column" mt={3}>
                <Text fontSize="5xl">
                    <Logo />
                </Text>
            </Flex>

            <Box pt={8}>
                <Divider />

                <Heading as="h3" fontSize="xl" pt={6} pb={3} mb={5}>
                    Eventos disponibles
                </Heading>

                <SimpleGrid width="100%" columns={[1, 2, 2, 3]} spacing={3}>
                    {isLoading && <ShowsLoading />}
                    {!isLoading &&
                        shows &&
                        shows.length !== 0 &&
                        shows.map((show: any, idx: number) => (
                            <ShowCard key={idx} show={show} />
                        ))}
                </SimpleGrid>
            </Box>
        </Flex>
    );
};

export const ShowsLoading = () => {
    return (
        <>
            <Box>
                <Skeleton
                    height="200px"
                    width={{ base: '100%', sm: '100%', md: '100%' }}
                />
            </Box>
            <Skeleton height="200px" width={{ base: '100%', sm: '100%' }} />
            <Skeleton height="200px" width={{ base: '100%', sm: '100%' }} />
        </>
    );
};
