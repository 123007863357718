import FingerprintJS from '@fingerprintjs/fingerprintjs';

const fpPromise = FingerprintJS.load();


/**
 * Save device in localStorage
 * Calculate fingerprint from browser
 */
export async function saveDevice() {
    const fp = await fpPromise
    const result = await fp.get()
    if (result && result.visitorId)
        localStorage.setItem('moonplay.device', result.visitorId);
}


/**
 * Get device (fingerprint previously saved in localStorage)
 * @returns 
 */
export function getDevice() {
    if (localStorage.getItem('moonplay.device'))
        return localStorage.getItem('moonplay.device');
    else
        return 'default';
}