// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getDatabase, ref } from "firebase/database";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCLJ7V0KGNwXlDjnoAv2r0VdolL9fy-oGM",
    authDomain: "beereal-streaming-prod.firebaseapp.com",
    databaseURL: "https://beereal-streaming-prod-default-rtdb.firebaseio.com",
    projectId: "beereal-streaming-prod",
    storageBucket: "beereal-streaming-prod.appspot.com",
    messagingSenderId: "538439173524",
    appId: "1:538439173524:web:f57ba77c2138cec98ebb81",
    measurementId: "G-R3DH4VCNXP"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const database = getDatabase(firebase);

/**
 * Log page_view in analytics
 */
export const logPageView = () => {
    logEvent(analytics, 'page_view');
}

/**
 * Ref object in real-time database
 * @param value 
 * @returns 
 */
export const syncValue = (value: string) => {
    return ref(database, value);
}
