import React, { useEffect, useState } from 'react';

import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';
import { syncValue } from '../../utils/firebase';
import { useObject } from 'react-firebase-hooks/database';
import { ENV } from '../../utils/constants';

export const Message = () => {
    const [snapshot]: any = useObject(syncValue(`/${ENV}/general/message`));
    const [message, setMessage]: any = useState(false);

    useEffect(() => {
        if (snapshot && snapshot.val) {
            setMessage(snapshot.val());
        }
    }, [snapshot]);

    if (!message || !message.enabled) return <></>;

    return (
        <Alert status={message.status || 'success'}>
            <AlertIcon />
            <AlertTitle mr={2}>{message.value}</AlertTitle>
        </Alert>
    );
};
