import React, { useEffect, useState } from 'react';
import { getSession, clearSession } from '../libs/session';

export const AuthContext = React.createContext<any>({} as any);

export default function AuthProvider({ children }: any) {
    const [authenticated, setAuthenticated] = useState(false);
    const [showId, setShowId] = useState(null);

    // When load site for first time (or in new tab/page)
    useEffect(() => {
        const session = getSession();

        // If session is valid, then just set authenticated = true
        if (session) {
            setShowId(session?.show.slug);
            setAuthenticated(true);
        }

        // Otherwise - remove all items from localStorage (in case they are there)
        // and set autenticated = false
        else {
            clearSession();
            setAuthenticated(false);
        }
    }, []);

    useEffect(() => {
        // When authenticated changes, then get the show id
        if (authenticated) {
            const session = getSession();
            if (session) setShowId(session?.show.slug);
        }
    }, [authenticated]);

    return (
        <AuthContext.Provider
            value={{ authenticated, showId, setAuthenticated }}
        >
            {children}
        </AuthContext.Provider>
    );
}
