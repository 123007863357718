import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/userAuth';
import { clearSession, getSession } from '../../libs/session';

export function PrivateRoute({ children, embed, ...rest }: any) {
    let { showId } = useParams();
    const { authenticated, setAuthenticated } = React.useContext(AuthContext);

    useEffect(() => {
        const checkSessionInternal = setInterval(() => {
            const session = getSession();
            if (!session) {
                clearSession();
                setAuthenticated(false);
            }
        }, 60000);

        return function cleanup() {
            clearInterval(checkSessionInternal);
        };
    }, [setAuthenticated]);

    return authenticated === true ? (
        children
    ) : embed ? (
        <Navigate to={showId ? '/embed/' + showId : '/'} />
    ) : (
        <Navigate to={showId ? '/' + showId : '/'} />
    );
}
