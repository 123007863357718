import React, { useEffect, useState } from 'react';

import {
    Flex,
    Box,
    Image,
    Stack,
    Skeleton,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
    AccessForm,
    ShowBox,
    SponsorBox,
    AccessError,
    AccessPublic,
} from '../access';
import { useQuery } from 'react-query';
import { findShowById } from '../../libs/show-api';
import { APP_NAME, ENV, CONTROL_PANEL, SHOW } from '../../utils/constants';
import { syncValue } from '../../utils/firebase';
import { useObject } from 'react-firebase-hooks/database';
import { ShowMessage } from '../show';

export const AccessScreen = (
    params: { embed?: boolean } = { embed: false }
) => {
    let { showId } = useParams();
    const { embed } = params;
    const [showSnapshot]: any = useObject(
        syncValue(`/${ENV}/shows/${showId}/${CONTROL_PANEL}`)
    );

    const [accessMode, setAccessMode] = useState(SHOW.ACCESS_MODE.VALIDATE);
    const [mode, setMode] = useState(SHOW.MODE.CLOSED);
    const [message, setMessage] = useState(null);

    const { status, data: show } = useQuery(['shows', showId], () =>
        findShowById('' + showId)
    );

    useEffect(() => {
        if (show) {
            document.title = `${APP_NAME} - ${show?.event?.name}`;
        }
    }, [show]);

    useEffect(() => {
        if (showSnapshot && showSnapshot.val) {
            const _showSnapshot = showSnapshot.val();
            if (_showSnapshot) {
                if (_showSnapshot.mode) setMode(_showSnapshot.mode);
                if (_showSnapshot.accessMode)
                    setAccessMode(_showSnapshot.accessMode);
                if (_showSnapshot.message) setMessage(_showSnapshot.message);
            }
        }
    }, [showSnapshot]);

    return (
        <Flex
            direction="column"
            pt={{ base: embed ? 1 : 4, sm: embed ? 1 : 12 }}
        >
            {showId && message && <ShowMessage message={message} />}

            {status === 'loading' && <LoadingShow embed={embed || false} />}

            {status === 'error' && <AccessError />}

            {status === 'success' && !show && <AccessError />}

            {status === 'success' && show && (
                <Flex direction="column">
                    {!embed && (
                        <Flex
                            flex={1}
                            direction="column"
                            mb={{ base: 4, sm: 8 }}
                        >
                            <SponsorBox sponsor={show.sponsor} />
                        </Flex>
                    )}

                    <Flex
                        spacing={{ base: 0, sm: embed ? 1 : 4 }}
                        direction={{ base: 'column', md: 'row' }}
                        flex={1}
                        width="100%"
                        mt={{ base: 2, sm: embed ? 2 : 10 }}
                        mb={5}
                    >
                        <Flex direction="column">
                            <ShowBox event={show.event} />
                            <Box
                                display={{ sm: 'block', md: 'none' }}
                                my={{ base: 0, sm: 4 }}
                                height={{ sm: '180px' }}
                            >
                                <ImageBox event={show.event} />
                            </Box>

                            <Box pt={4}>
                                <ShowAccess
                                    showId={showId}
                                    mode={mode}
                                    accessMode={accessMode}
                                />
                            </Box>
                        </Flex>
                        <Spacer display={{ sm: 'none', md: 'flex' }} />
                        <Flex
                            mt={{ base: '6', md: '0' }}
                            pl={{ base: 0, md: '6' }}
                            maxWidth={{ base: '100%', md: '50%' }}
                            width={'100%'}
                            align="start"
                            display={{ base: 'none', sm: 'none', md: 'block' }}
                        >
                            <ImageBox event={show.event} />
                        </Flex>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};

const LoadingShow = (props: { embed?: boolean } = { embed: false }) => {
    const { embed } = props;
    return (
        <Stack spacing={4}>
            {!embed && <Skeleton height="110px" />}
            <Stack
                direction={{ base: 'column', sm: 'row' }}
                spacing={embed ? 0 : 4}
            >
                <Skeleton
                    height={{ base: '80px', sm: '300px' }}
                    width={{ base: '100%', sm: '60%' }}
                />
                <Skeleton
                    height={{ base: '80px', sm: '300px' }}
                    width={{ base: '100%', sm: '40%' }}
                />
            </Stack>
        </Stack>
    );
};

const ShowAccess = ({ showId, mode, accessMode }: any) => {
    if (!mode) return <></>;

    let response = null;
    switch (mode) {
        case SHOW.MODE.OPEN:
            if (accessMode === SHOW.ACCESS_MODE.VALIDATE)
                response = <AccessForm showId={showId} />;
            else if (accessMode === SHOW.ACCESS_MODE.PUBLIC)
                response = <AccessPublic showId={showId} />;
            break;
        case SHOW.MODE.CLOSED:
            response = (
                <Text id="show_closed_msg">
                    El evento va a estar disponible a la hora estimada de
                    comienzo para que puedas ingresar y disfrutar de la
                    transmisión.
                </Text>
            );
            break;
        case SHOW.MODE.FINALIZED:
            response = (
                <Text id="show_finalized_msg">
                    El evento ha finalizado y ya no se encuentra disponible.
                </Text>
            );
            break;
    }

    return response;
};

const ImageBox = ({ event }: any) => {
    if (event && event.image && event.image.cover)
        return (
            <Box
                rounded={'sm'}
                boxShadow={'2xl'}
                width={'full'}
                overflow={'hidden'}
                mt={{ base: '6px', sm: 0 }}
            >
                <Image
                    height="30px"
                    fit={'contain'}
                    w={'100%'}
                    h={'100%'}
                    src={event.image.cover}
                    _hover={{
                        transform: 'scale(1.01)',
                    }}
                />
            </Box>
        );
    else return <></>;
};
