import { Flex, Spacer, useColorMode } from '@chakra-ui/react';
import { useEffect } from 'react';

export const Footer = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    /* const [isDisplayLogo, setDisplayLogo] = useState(true); */

    useEffect(() => {
        if (colorMode === 'light') {
            toggleColorMode();
        }
    }, [colorMode, toggleColorMode]);

    /* function handleToggleDisplayLogo() {
        setDisplayLogo(!isDisplayLogo);
    } */

    return (
        <Flex
            direction={{ sm: 'row' }}
            align="center"
            w={{ base: '95%', sm: '80%' }}
            pt={{ base: 8, sm: 6 }}
            px={5}
            flex={1}
            margin="auto"
            pb={5}
        >
            <Flex direction="row">
                {/* <Link href={'https://nztstudios.com/'} isExternal>
                    <Image height="40px" mr={4} src={'/general/nzt_logo.png'} />
                </Link> */}
            </Flex>
            <Spacer />
            <Flex direction="row">
                {/* <Box width="30px" onClick={handleToggleDisplayLogo}></Box>
                {isDisplayLogo ? (
                    <Flex direction="row" align="center">
                        {ENV !== 'live' && <Text mr={2}>{ENV}</Text>}
                        <Link href={'https://beerealit.com'} isExternal>
                            <Image height="15px" src={'/beereal_logo.png'} />
                        </Link>
                    </Flex>
                ) : (
                    <Text>
                        v{CURRENT_VERSION} {TIMESTAMP_VERSION}
                    </Text>
                )} */}
            </Flex>
        </Flex>
    );

    /*
    return (
        <Container
            as={Stack}
            maxW={'6xl'}
            width="100%"
            direction={{ base: 'column', md: 'row' }}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}
        >
            <Stack direction={'row'}>
                <Link href={'#'}>Home</Link>
                <Link href={'#'}>Acerca de</Link>
            </Stack>
            <Text>© 2021 BeeReal. All rights reserved</Text>
        </Container>
    );
    */
};
