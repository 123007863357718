import { Image } from '@chakra-ui/react';

export const Logo = () => {
    return (
        <Image
            height={{ base: '60px', sm: '80px', lg: '100px' }}
            src={'/moonplay_logo.png'}
            alt="MOONPLAY"
        />
    );
};
