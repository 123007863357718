import React, { useEffect, useState } from 'react';

import {
    Flex,
    Text,
    InputGroup,
    Input,
    InputRightElement,
    Button,
    Alert,
    AlertTitle,
    AlertIcon,
    Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';
import { validateAccessCode } from '../../libs/show-api';
import { FaPlayCircle } from 'react-icons/fa';
import { AuthContext } from '../../contexts/userAuth';

export const AccessForm = (props: any) => {
    const { showId } = props;
    const { width, height } = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [displayMode, setDisplayMode] = useState('form');
    const [ticket, setTicket] = useState<any>(null);
    const { setAuthenticated } = React.useContext(AuthContext);

    const { register, handleSubmit, formState } = useForm({
        mode: 'onChange',
    });

    const onSubmit = (data: any) => {
        setIsLoading(true);
        setError(null);
        validateAccessCode(showId, data.accessCode)
            .then((data: any) => {
                setTicket(data.ticket);
                setDisplayMode('success');
                setIsLoading(false);
                setTimeout(() => {
                    setAuthenticated(true);
                }, 5000);
            })
            .catch((err) => {
                setError(err.msg);
                setErrorCode(err.code);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 10000);
        }
    }, [error]);

    const handleAccessClick = () => {
        setAuthenticated(true);
    };

    return (
        <Flex direction="column">
            {displayMode === 'form' && (
                <>
                    <form onSubmit={handleSubmit(onSubmit)} id="validateForm">
                        <InputGroup size="lg">
                            <Input
                                pr="4.5rem"
                                autoFocus
                                type="text"
                                maxLength={25}
                                bgColor="gray.900"
                                placeholder="Código de acceso"
                                autoComplete="off"
                                autoCapitalize="off"
                                id="accessCode"
                                isDisabled={isLoading}
                                {...register('accessCode', {
                                    required: true,
                                    minLength: 3,
                                })}
                            />
                            <InputRightElement
                                width="auto"
                                display={{ base: 'none', sm: 'flex' }}
                            >
                                <Button
                                    h="2rem"
                                    size="lg"
                                    onClick={handleSubmit(onSubmit)}
                                    mr={2}
                                    bgColor="white"
                                    color="black"
                                    _hover={{
                                        background: 'gray.300',
                                    }}
                                    isLoading={isLoading}
                                    disabled={!formState.isValid}
                                    id="validateBtn"
                                >
                                    Validar ticket
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <Button
                            display={{ base: 'flex', sm: 'none' }}
                            size="lg"
                            width="100%"
                            bgColor="white"
                            color="black"
                            _hover={{
                                background: 'gray.300',
                            }}
                            mt={2}
                            onClick={handleSubmit(onSubmit)}
                            isLoading={isLoading}
                            disabled={!formState.isValid}
                            id="validateMobileBtn"
                        >
                            Validar ticket
                        </Button>
                    </form>
                    {error && (
                        <Box id={errorCode || ''}>
                            <Alert status="error" mt={2} borderRadius="6px">
                                <AlertIcon />
                                <AlertTitle mr={2}>{error}</AlertTitle>
                            </Alert>
                        </Box>
                    )}

                    <Text pt={4} color="gray.400" fontSize="sm" pl={1}>
                        * Los tickets no se encuentran a la venta y son
                        entregados por quien organiza el evento.
                    </Text>
                </>
            )}

            {displayMode === 'success' && (
                <Flex direction="column" id="ticket_validated_success">
                    <Confetti width={width} height={height} />
                    <Text mb={4} fontSize="xl">
                        ¡Muy bien! Tu ticket es{' '}
                        <Text as="span" fontWeight="bold">
                            {ticket.category.code !== 'default' &&
                                ticket.category.name}
                        </Text>
                        <Text as="span">
                            {ticket.category.code === 'default' && 'válido'}
                        </Text>
                    </Text>
                    <Flex
                        direction={{ base: 'column', sm: 'row' }}
                        align="center"
                        width="100%"
                    >
                        <Button
                            fontWeight="bold"
                            size="lg"
                            width={{ base: '100%', sm: 'auto' }}
                            bgColor="white"
                            color="black"
                            _hover={{
                                background: 'gray.300',
                            }}
                            onClick={handleAccessClick}
                        >
                            <Text mr={2}>Ver el Evento</Text> <FaPlayCircle />
                        </Button>
                        <Text pt={{ base: '3', sm: '0' }} ml={3}>
                            Será redirigido en unos segundos...
                        </Text>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};
