import React, { useEffect, useState } from 'react';

import {
    Flex,
    Button,
    Stack,
    Spacer,
    Skeleton,
    Image,
    Link,
} from '@chakra-ui/react';
import { Player } from '../player';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../libs/show-api';
import { AuthContext } from '../../contexts/userAuth';
import { APP_NAME, ENV, CONTROL_PANEL, SHOW } from '../../utils/constants';
import { getSession } from '../../libs/session';
import { useObject } from 'react-firebase-hooks/database';
import { syncValue } from '../../utils/firebase';
import { ShowMessage } from '../show';

export const WatchScreen = () => {
    const [ticket, setTicket] = useState<any>(null);
    const [show, setShow] = useState<any>(null);
    const [message, setMessage] = useState<any>(null);
    const [player, setPlayer] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLogOutLoading, setIsLogOutLoading] = useState(false);
    const { setAuthenticated } = React.useContext(AuthContext);
    const navigate = useNavigate();

    const [showSnapshot]: any = useObject(
        show && syncValue(`/${ENV}/shows/${show.slug}/${CONTROL_PANEL}`)
    );

    useEffect(() => {
        const session = getSession();
        if (session) {
            setTicket(session.ticket);
            setShow(session.show);
            setPlayer(session.player);
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }, []);

    useEffect(() => {
        if (show) {
            document.title = `${APP_NAME} - ${show?.event?.name}`;
        }
    }, [show]);

    useEffect(() => {
        if (showSnapshot && showSnapshot.val) {
            const _showSnapshot = showSnapshot.val();

            if (_showSnapshot) {
                if (_showSnapshot.message) setMessage(_showSnapshot.message);

                // If show mode is not open, then logout
                if (_showSnapshot.mode !== SHOW.MODE.OPEN) {
                    setIsLogOutLoading(true);
                    logout(show && show.slug, ticket && ticket.accessCode)
                        .then((data) => {
                            setIsLogOutLoading(false);
                            setAuthenticated(false);
                            if (show && show.slug) navigate('/' + show.slug);
                            else navigate('/');
                        })
                        .catch((err) => {
                            setAuthenticated(false);
                            setIsLogOutLoading(false);
                            if (show && show.slug) navigate('/' + show.slug);
                            else navigate('/');
                        });
                }
            }
        }
    }, [showSnapshot, navigate, setAuthenticated, show, ticket]);

    function handleLogOut() {
        setIsLogOutLoading(true);
        logout(show && show.slug, ticket && ticket.accessCode)
            .then((data) => {
                setIsLogOutLoading(false);
                setAuthenticated(false);
                if (show && show.slug) navigate('/' + show.slug);
                else navigate('/');
            })
            .catch((err) => {
                setAuthenticated(false);
                setIsLogOutLoading(false);
                if (show && show.slug) navigate('/' + show.slug);
                else navigate('/');
            });
    }

    return (
        <Flex direction="column" pt={3}>
            {message && <ShowMessage message={message} />}

            <Flex textAlign="right" width="100%" flex={1} mb={4} align="center">
                {show && show.sponsor && (
                    <SponsorImageBox sponsor={show.sponsor} />
                )}
                <Spacer />
                <Button
                    id="logoutBtn"
                    onClick={handleLogOut}
                    isLoading={isLogOutLoading}
                >
                    Salir
                </Button>
            </Flex>

            {isLoading && <LoadingWatch />}

            {!isLoading && ticket && <Player player={player} />}
        </Flex>
    );
};

const LoadingWatch = () => {
    return (
        <Stack direction="column" mt={2}>
            <Skeleton height="800px" />
        </Stack>
    );
};

const SponsorImageBox = ({ sponsor }: any) => {
    if (!(sponsor && sponsor.image && sponsor.image.logo)) return <></>;

    return (
        <Flex height={{ base: '40px', sm: '50px' }} pt={{ base: 2, sm: 0 }}>
            {sponsor.website && sponsor.website.url ? (
                <Link maxW={'261.11px'} href={sponsor.website.url} isExternal>
                    <Image
                        fit={'cover'}
                        src={sponsor.image.logo}
                        alt={sponsor.name}
                        height={{ base: '40px', sm: '50px' }}
                        _hover={{
                            transform: 'scale(1.01)',
                        }}
                    />
                </Link>
            ) : (
                <Image
                    fit={'cover'}
                    src={sponsor.image.logo}
                    alt={sponsor.name}
                    _hover={{
                        transform: 'scale(1.01)',
                    }}
                />
            )}
        </Flex>
    );
};
