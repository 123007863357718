import React, { useState } from 'react';

import { Tabs, TabList, Tab, Text } from '@chakra-ui/react';
import { EmbedPlayer } from './EmbedPlayer';
import { HLSPlayer } from './HLSPlayer';

export const Player = (props: any) => {
    const { player } = props;
    const [activePlayer, setActivePlayer] = useState(player[0]);

    if (!activePlayer) return null;

    const handleSelectPlayer = (idx: number) => {
        if (idx < player.length) {
            const newActivePlayer: any = player[idx];
            if (newActivePlayer) setActivePlayer(newActivePlayer);
        }
    };

    return (
        <>
            <VideoSwitcher
                player={player}
                onSelectPlayer={handleSelectPlayer}
            />

            {activePlayer && activePlayer.type === 'embed' && (
                <EmbedPlayer url={activePlayer.value} />
            )}
            {activePlayer && activePlayer.type === 'hls' && (
                <HLSPlayer hls={activePlayer.value} />
            )}
        </>
    );
};

const VideoSwitcher = (props: any) => {
    const { player, onSelectPlayer } = props;
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (idx: number) => {
        setActiveIndex(idx);
        onSelectPlayer(idx);
    };

    if (!player || player.length < 2) return null;

    return (
        <Tabs onChange={(index) => handleClick(index)} mb={2}>
            <TabList>
                {player.map((key: string, idx: number) => (
                    <Tab
                        color="white"
                        key={idx}
                        _selected={{
                            color: 'white',
                            borderBottom: '2px solid white',
                        }}
                    >
                        <Text
                            fontWeight={activeIndex === idx ? 'bold' : 'normal'}
                        >
                            {player[idx].label || `Player #${idx + 1}`}
                        </Text>
                    </Tab>
                ))}
            </TabList>
        </Tabs>
    );
};
