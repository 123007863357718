import React, { useEffect } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
    OtherScreen,
    WatchScreen,
    AccessScreen,
    HomeScreen,
} from './components/screens';
import { Layout } from './components/layout';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthProvider from './contexts/userAuth';
import theme from './theme';
import { PublicRoute, PrivateRoute } from './components/common';
import { saveDevice } from './utils/fingerprint';

// Create a client
const queryClient = new QueryClient();

function App() {
    useEffect(() => {
        // Save device in localStorage
        saveDevice();
    }, []);

    return (
        <ChakraProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <BrowserRouter>
                        <Layout>
                            <Routes>
                                <Route path="/" element={<HomeScreen />} />
                                <Route
                                    path="/:showId"
                                    element={
                                        <PublicRoute>
                                            <AccessScreen />
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/:showId/watch"
                                    element={
                                        <PrivateRoute>
                                            <WatchScreen />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/embed/:showId"
                                    element={
                                        <PublicRoute embed={true}>
                                            <AccessScreen embed={true} />
                                        </PublicRoute>
                                    }
                                ></Route>
                                <Route
                                    path="/embed/:showId/watch"
                                    element={
                                        <PrivateRoute embed={true}>
                                            <WatchScreen />
                                        </PrivateRoute>
                                    }
                                ></Route>
                                <Route path="*" element={<OtherScreen />} />
                            </Routes>
                        </Layout>
                    </BrowserRouter>
                </AuthProvider>
            </QueryClientProvider>
        </ChakraProvider>
    );
}

export default App;
