import React, { useEffect } from 'react';

import { Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { Footer } from './Footer';
import { logPageView } from '../../utils/firebase';
import { Message } from './Message';

export const Layout = (props: any) => {
    const { children } = props;

    const location = useLocation();

    useEffect(() => {
        // Track page_view in google analytics
        logPageView();

        // Scroll to top
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location]);

    return (
        <Flex
            as="main"
            direction="column"
            w="100%"
            align="top"
            minH="100vh"
            bgColor="black"
        >
            <Flex
                direction="column"
                align="left"
                w={{ base: '95%', sm: '80%' }}
                pt={3}
                px={5}
                flex={1}
                margin="auto"
                minH={{ base: '85vh', sm: '90vh' }}
            >
                <Message />
                {children}
            </Flex>
            <Footer />
        </Flex>
    );
};
