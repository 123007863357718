import React from 'react';

import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';

export const ShowMessage = ({ message }: any) => {
    if (!message || !message.enabled) return <></>;
    return (
        <Alert status={message.status || 'success'} mb={4}>
            <AlertIcon />
            <AlertTitle mr={2}>{message.value}</AlertTitle>
        </Alert>
    );
};
