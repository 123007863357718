import dateFormat, { i18n } from 'dateformat';

i18n.dayNames = [
    "Dom",
    "Lun",
    "Mar",
    "Mie",
    "Jue",
    "Vie",
    "Sáb",
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
];
i18n.monthNames = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
    "Enero", // I changed just the full months' names to Portuguese
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
];
i18n.timeNames = ["a", "p", "am", "pm", "A", "P", "AM", "PM"];


/**
 * Format date
 * @param date string 'yyyy-mm-dd hh:mm:ss'
 * @param options 
 * @returns 
 */
export const formatDate = (date: string, options = 'fullDate') => {

    if (date) {
        const now = new Date(date.replace(/-/g, "/"));
        return dateFormat(now, options);
    }
    return date;
}