import React from 'react';

import { Flex, Text, Box, Heading, Badge } from '@chakra-ui/react';
import { formatDate } from '../../utils/formatter';

export const ShowBox = (props: any) => {
    const { event } = props;

    if (!event) return <></>;

    return (
        <Flex direction="column" spacing={4}>
            {event && event.date && (
                <Flex mb={{ base: 1, sm: 4 }} direction="row" align="center">
                    <Box>
                        <Badge
                            colorScheme="red"
                            bgColor="red.600"
                            color="white"
                            fontSize="md"
                            px={2}
                        >
                            LIVE
                        </Badge>
                    </Box>
                    <Text ml="3">
                        {formatDate(event.date, 'dddd, d mmm yyyy, h:MM TT')}
                    </Text>
                </Flex>
            )}
            <Heading
                display={{ base: 'none', sm: 'none', md: 'block' }}
                fontSize="4xl"
                pt={{ base: 1, sm: 4 }}
                mb={{ base: 0, sm: 4 }}
            >
                {event.name}
            </Heading>
            {event.description && (
                <Box
                    display={{ base: 'none', sm: 'none', md: 'block' }}
                    mb={{ base: 1, sm: 4 }}
                    fontSize="xl"
                    pr={4}
                    dangerouslySetInnerHTML={{ __html: event?.description }}
                ></Box>
            )}
        </Flex>
    );
};
