import React from 'react';

import { Flex, Heading } from '@chakra-ui/react';

export const OtherScreen = (props: any) => {
    return (
        <Flex direction="column" pt={8}>
            <Heading mb={8}>Other</Heading>
        </Flex>
    );
};
